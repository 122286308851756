




































import Btn from '../../../../Misc/Btn/Btn.vue';

export default {
    name: 'TeaserModule',
    components: {Btn},
    props: {
        title: String,
        teaserText: String,
        layout: String,
        link: String,
        applicationLink: String
    },
    computed: {}
};
