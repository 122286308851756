var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teaserModule" }, [
    _c("div", { staticClass: "teaserModule__container" }, [
      _c("div", { staticClass: "teaserModule__teaser" }, [
        _c("h5", { staticClass: "teaserModule__teaserTitle" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c("p", {
          staticClass: "teaserModule__teaserText",
          domProps: { innerHTML: _vm._s(_vm.teaserText) },
        }),
      ]),
      _vm._v(" "),
      _vm.layout === "findJob"
        ? _c(
            "div",
            {
              staticClass:
                "teaserModule__buttons teaserModule__buttons--narrow",
            },
            [
              _c("btn", { attrs: { href: _vm.link } }, [
                _vm._v(_vm._s(_vm.$t("navigation.teaser.btn.jobMarket"))),
              ]),
              _vm._v(" "),
              _c(
                "btn",
                { attrs: { "is-ghost": "", href: _vm.applicationLink } },
                [_vm._v(_vm._s(_vm.$t("navigation.teaser.btn.application")))]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "teaserModule__buttons" },
            [
              _c("btn", { attrs: { href: _vm.link } }, [
                _vm._v(_vm._s(_vm.$t("navigation.teaser.btn.overview"))),
              ]),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }